<template>
    <div>
      <CSidebarNavTitle v-if="$can('mail_reportes.index')" >MAILING</CSidebarNavTitle>
      <CSidebarNavItem
        name="Grupos Mail"
        to="/mailing/grupos"
        :icon="{ name: 'cil-group' }"
        v-if="$can('mail_grupos.index')"
      />
      <CSidebarNavDropdown
       name="Boletines"
       to="/mailing/boletines"
       :icon="{ name: 'cil-newspaper' }"
       :items="itemsBoletin"
       v-if="$can('mail_boletines.index')"
      />
      <CSidebarNavItem
        name="Estadisticas"
        to="/mailing/estadisticas"
        :icon="{ name: 'cil-chart' }"
        v-if="$can('mail_estadisticas.index')"
      />
      <CSidebarNavItem
        name="Reportes"
        to="/mailing/reportes"
        :icon="{ name: 'cil-clear-all' }"
        v-if="$can('mail_reportes.index')"
      />
      <CSidebarNavItem
        name="Busqueda Mail"
        to="/mailing/busqueda/email"
        :icon="{ name: 'cil-search' }"
        v-if="$can('mail_busqueda_detalle.index')"
      />
      <CSidebarNavItem
        name="Emails Bloqueados"
        to="/mailing/bloqueados"
        :icon="{ name: 'cil-bold' }"
        v-if="$can('mail_lista_negra.index')"
      />
      <CSidebarNavItem
        name="Usuarios"
        to="/mailing/usuarios"
        :icon="{name:'cil-user-plus'}"
        v-if="$can('mail_usuarios.index')"
      />
    </div>
</template>

<script>
    export default {
        name: "MailSidebar",
        data() {
            return {
                itemsBoletin: [
                    {   name:"Todos Los Boletines", to:"/mailing/boletines" },
                    {   name:"Envios Programados", to:"/mailing/cargues" },
                ],
            }
        }
    };
</script>
