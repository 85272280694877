<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <VerNoticiaUsuarios />
            <div
              :style="
                'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display: flex;opacity: 0.3;background-color: #fff;z-index: 10000;text-align: center;justify-content: center;align-items: center;visibility:' +
                visibleLoading
              "
            >
              <CSpinner style="width: 8rem; height: 8rem" color="info" grow />
            </div>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar.vue";
import TheHeader from "./TheHeader.vue";
import TheFooter from "./TheFooter.vue";
import VerNoticiaUsuarios from "@/views/noticias/VerNoticiaUsuarios.vue";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    VerNoticiaUsuarios,
  },
  computed: {
    visibleLoading() {
      return this.$store.state.auth.loading_http;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
