<template>
  <CFooter :fixed="false">
    <div>
      <!--<a href="https://coreui.io" target="_blank">It Cloud Services</a>-->
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Todos Los Derechos Reservados.</span>
    </div>
    <div class="mfs-auto">
      <!--<span class="mr-1" target="_blank">Creado Por</span>
      <a href="https://coreui.io/vue">It Cloud Services</a>-->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
