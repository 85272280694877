<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div style="display: contents">
        <strong style="margin-right: 15px"> Sms Masivo </strong>
        <div class="c-avatar" style="width: 70px; height: 70px">
          <img :src="obtenerLogo" class="c-avatar-img" :style="fondoLogo" />
        </div>
      </div>
      <!--<CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      />-->
    </CSidebarBrand>
    <CSidebarBrand class="" to="/">
      <div style="display: grid; justify-items: center">
        <strong style="margin-right: 15px">{{ nombreUser }}</strong>
        <strong style="margin-right: 15px">{{ emailUser }}</strong>
      </div>
    </CSidebarBrand>
    <!--<CRenderFunction flat :content-to-render="computedNav"/>-->
    <CSidebarNav>
      <CSidebarNavItem
        name="Inicio"
        to="/dashboard"
        :icon="{ name: 'cil-home' }"
      />
      <CSidebarNavTitle>SMS</CSidebarNavTitle>
      <CSidebarNavItem
        name="Mensajes de Texto"
        to="/mensajes/texto"
        :icon="{ name: 'cil-screen-smartphone' }"
        v-if="$can('mensaje_texto.index')"
      />
      <CSidebarNavItem
        name="Cargues Programados"
        to="/cargues"
        :icon="{ name: 'cil-speedometer' }"
        v-if="$can('cargues.index')"
      />
      <CSidebarNavItem
        name="Estadisticas"
        to="/estadisticas"
        :icon="{ name: 'cil-chart' }"
      />
      <CSidebarNavItem
        name="Usuarios"
        to="/usuarios"
        :icon="{ name: 'cil-user-plus' }"
        v-if="$can('usuario.index')"
      />
      <CSidebarNavItem
        name="Link Fraude"
        to="/logfraude"
        :icon="{ name: 'cil-link-alt' }"
        v-if="$can('usuario.index')"
      />
      <CSidebarNavItem
        name="Roles y Permisos"
        to="/roles"
        :icon="{ name: 'cil-clear-all' }"
        v-if="$can('rol.index')"
      />
      <CSidebarNavItem
        name="Precio Sms"
        to="/precio/sms"
        :icon="{ name: 'cil-money' }"
        v-if="$can('precio.precio_sms')"
      />
      <CSidebarNavItem
        name="Sub Cuentas"
        to="/subcuentas/usuarios"
        :icon="{ name: 'cil-group' }"
        v-if="$can('subcuenta.index') && validaMarcaBlanca == 1"
      />
      <CSidebarNavItem
        name="Arbol Usuarios"
        to="/usuarios/arbol"
        :icon="{ name: 'cil-clear-all' }"
        v-if="$can('arbol.usuarios')"
      />
      <CSidebarNavItem
        name="Grupos"
        to="/grupos"
        :icon="{ name: 'cil-group' }"
        v-if="$can('mensaje_texto.index')"
      />
      <CSidebarNavItem
        name="Noticias"
        to="/noticias"
        :icon="{ name: 'cil-newspaper' }"
        v-if="$can('noticias.index')"
      />
      <CSidebarNavItem
        name="Palabras Spam"
        to="/palabras"
        :icon="{ name: 'cil-newspaper' }"
        v-if="$can('usuario.palabra_spam')"
      />
      <CSidebarNavItem
        name="Historial Recarga"
        to="/historial/recarga"
        :icon="{ name: 'cil-justify-left' }"
        v-if="$can('historial_recarga.index')"
      />
      <CSidebarNavItem
        name="Reportes"
        to="/reportes"
        :icon="{ name: 'cil-chart' }"
        v-if="$can('reportes.index')"
      />
      <CSidebarNavItem
        name="Recarga Pse"
        to="/recarga/pse"
        :icon="{ name: 'cil-credit-card' }"
        v-if="validaOpcionpse == 'OK'"
      />
      <CSidebarNavItem
        name="Numeros Bloqueados"
        to="/numeros/bloqueados"
        :icon="{ name: 'cil-bold' }"
        v-if="$can('lista_negra.index')"
      />
      <CSidebarNavItem
        name="Chatbots SMS"
        to="/designer"
        :icon="{ name: 'cil-memory' }"
        :badge="{
          color: 'danger',
          text: 'Nuevo',
        }"
      />
      <CSidebarNavItem
        name="Configuraciones"
        @click.native="modalConfiguraciones"
        :icon="{ name: 'cil-settings' }"
      />
      <MailSidebar/>
      <CSidebarNavTitle>OTROS</CSidebarNavTitle>
      <CSidebarNavItem
        name="Centro de Ayuda"
        to="/contenidoayuda"
        :icon="{ name: 'cil-featured-playlist' }"
        v-if="validaUsuarioPadre == 0"
      />
      <CSidebarNavItem
        name="Cambiar Contraseña"
        @click.native="modalcambiarClave"
        :icon="{ name: 'cil-lock-locked' }"
      />
    </CSidebarNav>
    <!--<CRenderFunction flat :content-to-render="$options.nav"/>-->
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
    <div>
      <CModal
        :show.sync="cambiar_clave_modal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        color="dark"
      >
        <CForm validate @submit.prevent="cambiarClave">
          <CRow class="ml-1">
            <CCol col="12">
              <CInput
                placeholder="Anterior Contraseña"
                type="password"
                v-model="form.anterior_password"
                required
                was-validated
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>
            </CCol>
            <CCol col="12">
              <CInput
                placeholder="Nueva Contraseña"
                type="password"
                v-model="form.password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                required
                was-validated
                title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 caracteres o más"
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>
            </CCol>
            <CCol col="12">
              <CInput
                placeholder="Confirmar Contraseña"
                type="password"
                v-model="form.comfirm_password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                required
                was-validated
                title="Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 6 caracteres o más"
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>
            </CCol>
          </CRow>
          <br />
          <CRow class="ml-1 justify-content-center">
            <CCol col="12" class="text-center">
              <CButton @click="cambiar_clave_modal = false" color="danger"
                >Cancelar</CButton
              >
              <CButton color="success" type="submit">Guardar</CButton>
            </CCol>
          </CRow>
        </CForm>
        <template #header>
          <h6 class="modal-title">Cambiar Contraseña</h6>
          <CButtonClose
            @click="cambiar_clave_modal = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton
            @click="cambiar_clave_modal = false"
            color="danger"
            style="display: none"
            >Cancelar</CButton
          >
          <CButton color="success" @click="cambiarClave()" style="display: none"
            >Guardar</CButton
          >
        </template>
      </CModal>
      <CModal
        :show.sync="configuraciones_modal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        color="dark"
        size="lg"
      >
        <CForm validate @submit.prevent="cambiarConfiguraciones">
          <CRow class="ml-1">
            <CCol col="12">
              <CRow>
                <CCol col="8">
                  <CInput
                    placeholder="Dar Click en Generar Token Api"
                    autocomplete="off"
                    minlength="6"
                    required
                    was-validated
                    v-model="form_config.token_integracion"
                    disabled="true"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                </CCol>
                <CCol col="4">
                  <CButton size="sm" color="primary" @click="generarToken()"
                    >Generar Token Api</CButton
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol col="8">
                  <label for="" style="color: black"
                    >Saldo Minimo para Notificar Saldo</label
                  >
                  <CInput
                    placeholder="Notificacion Saldo"
                    autocomplete="off"
                    type="text"
                    min="2"
                    minlength="2"
                    tabindex="0"
                    id="uid-ip8fkjcnasjl-caskjask"
                    v-model.lazy="form_config.notificacion_saldo"
                    v-money="money"
                    @keyup="validaCantidadMaxima"
                  >
                  </CInput>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="8">
                  <label for="" style="color: black"
                    >Respuesta Web Doble Via</label
                  >
                  <CInput
                    placeholder="Respuesta Web Doble Via"
                    autocomplete="off"
                    v-model="form_config.Web_Respuesta"
                  >
                  </CInput>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <br />
          <CRow class="ml-1 justify-content-center">
            <CCol col="12" class="text-center">
              <CButton @click="configuraciones_modal = false" color="danger"
                >Cancelar</CButton
              >
              <CButton color="success" type="submit">Guardar</CButton>
            </CCol>
          </CRow>
        </CForm>
        <template #header>
          <h6 class="modal-title">Configuraciones</h6>
          <CButtonClose
            @click="configuraciones_modal = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton
            @click="configuraciones_modal = false"
            color="danger"
            style="display: none"
            >Cancelar</CButton
          >
          <CButton
            color="success"
            @click="cambiarConfiguraciones()"
            style="display: none"
            >Guardar</CButton
          >
        </template>
      </CModal>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { VMoney } from "v-money";
import MailSidebar from './MailSidebar.vue';
export default {
  name: "TheSidebar",
  components: { VMoney,MailSidebar },
  nav,
  data() {
    return {
      mostrarNotificacion: 0,
      mensaje_success: "Datos Guardado Correctamente.",
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      cambiar_clave_modal: false,
      configuraciones_modal: false,
      form: {
        anterior_password: "",
        password: "",
        comfirm_password: "",
      },
      form_config: {
        token_integracion: "",
        notificacion_saldo: 0,
        Web_Respuesta: "",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    obtenerLogo() {
      return this.$store.state.auth.logo_web;
    },
    fondoLogo() {
      try {
        let url = this.$store.state.auth.logo_web;
        if (url.search("img/avatars") >= 0) {
          return "background:white;";
        } else {
          return "";
        }
      } catch (error) {
        return "";
      }
    },
    nombreUser() {
      return this.$store.state.auth.user.name;
    },
    emailUser() {
      return this.$store.state.auth.user.email;
    },
    computedNav() {
      const permissions = this.$store.state.auth.permissions;
      const rutas = this.$options.nav[0]["_children"];
      let nuevo_array = [];
      for (const item of rutas) {
        for (const iterator of permissions) {
          if (item.name == "Inicio" || item._name == "CSidebarNavTitle") {
            let p = nuevo_array.filter((i) => i.name == item.name);
            if (p.length == 0) {
              nuevo_array.push(item);
            }
          } else if (
            item._name == "CSidebarNavItem" &&
            item.permisos == iterator.name
          ) {
            nuevo_array.push(item);
          }
        }
      }
      return [{ _children: nuevo_array, _name: "CSidebarNav" }];
    },
    validaMarcaBlanca() {
      try {
        return this.$store.state.auth.user.sms.marca_blanca;
      } catch (error) {
        return 0;
      }
    },
    validaOpcionpse() {
      try {
        return this.$store.state.auth.psevalida.respuesta;
      } catch (error) {
        return "NO";
      }
    },
    validaUsuarioPadre() {
      try {
        return this.$store.state.auth.user.padre;
      } catch (error) {
        return 0;
      }
    },
  },
  methods: {
    cambiarClave: function () {
      if (this.form.comfirm_password !== this.form.password) {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Las Contraseñas No Coinciden";
        return false;
      } else if (
        this.form.comfirm_password.trim() == "" &&
        this.form.password.trim() == ""
      ) {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "Debe Completar Los Datos Vacio";
        return false;
      } else if (this.form.password.length < 6) {
        this.mostrarNotificacionAlerta++;
        this.mensaje_warning = "La Contraseña debe Tener Minimo 6 Caracteres";
        return false;
      } else {
        this.$store.dispatch("auth/loading_http", "visible");
        let id = this.$store.state.auth.user.id;
        this.$http
          .post("/sms/cambiar/clave", {
            iduser: id,
            password: this.form.password,
            anterior_password: this.form.anterior_password,
          })
          .then((response) => {
            let data = response.data;
            if (data == "OK") {
              this.mostrarNotificacion++;
              this.form.anterior_password = "";
              this.form.comfirm_password = "";
              this.form.password = "";
              this.cambiar_clave_modal = false;
            } else if (data == "NO") {
              this.mostrarNotificacionAlerta++;
              this.mensaje_warning = "La Contraseña Anterior No Es Correcta";
            } else {
              this.form.anterior_password = "";
              this.form.comfirm_password = "";
              this.form.password = "";
              this.cambiar_clave_modal = false;
            }
          })
          .catch((error) => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      }
    },
    modalcambiarClave: async function () {
      this.cambiar_clave_modal = true;
      this.form.anterior_password = "";
      this.form.comfirm_password = "";
      this.form.password = "";
    },
    modalConfiguraciones: async function () {
      this.obtenerConfiguracionesUsuarios();
      this.configuraciones_modal = true;
      //this.form_config.token_integracion = this.$store.state.auth.user.token_integracion;
    },
    cambiarConfiguraciones: function () {
      //let valida_saldo = this.validaTieneSaldoInput(document.getElementById("uid-ip8fkjcnasjl-caskjask").value);
      /*if (valida_saldo.toString().length < 2) {
          this.mostrarNotificacionAlerta++
          this.mensaje_warning='Saldo Debe Tener Minimos 3 Caracteres'
          return false
      }*/
      /*if (this.form_config.token_integracion == '' || valida_saldo == 0) {
          this.mostrarNotificacionAlerta++
          this.mensaje_warning='Debe Completar Los Datos Vacio'
       } else {*/
      this.$store.dispatch("auth/loading_http", "visible");
      let id = this.$store.state.auth.user.id;
      this.$http
        .post("/sms/cambiar/configuraciones", {
          token_integracion: this.form_config.token_integracion,
          notificacion_saldo: document.getElementById(
            "uid-ip8fkjcnasjl-caskjask"
          ).value, //this.form_config.notificacion_saldo,
          Web_Respuesta: this.form_config.Web_Respuesta,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            this.mostrarNotificacion++;
            this.configuraciones_modal = false;
            this.$store.dispatch("auth/getUser").catch(() => {});
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      //}
    },
    generarToken() {
      this.form_config.token_integracion = this.token();
    },
    random() {
      return Math.random().toString(36).substr(2); // Eliminar `0.`
    },
    token() {
      return this.random() + this.random(); // Para hacer el token más largo
    },
    validaTieneSaldoInput(credi) {
      let texto = credi;
      texto = texto.replaceAll(".", "");
      return parseInt(texto);
    },
    creditosConversorMoneda(value) {
      const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatterDolar.format(value).replace("$", "");
    },
    validaCantidadMaxima(event) {
      let texto = event.target.value;
      let maximo = this.creditosConversorMoneda(9000000);
      maximo = maximo.replaceAll(",", "");
      maximo = maximo.replaceAll(".", ",");
      texto = texto.replaceAll(".", "");
      if (parseInt(texto) > parseInt(maximo)) {
        event.target.value = "0,00";
      }
    },
    obtenerConfiguracionesUsuarios() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/cambiar/configuraciones")
        .then((response) => {
          let data = response.data;
          this.form_config.token_integracion = data.token_integracion;
          this.form_config.notificacion_saldo = parseInt(
            data.notificacion_saldo
          );
          document.getElementById("uid-ip8fkjcnasjl-caskjask").value = parseInt(
            data.notificacion_saldo
          );
          this.form_config.Web_Respuesta = data.Web_Respuesta;
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
  },
};
</script>
