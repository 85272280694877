<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--<CIcon name="logo" height="48" alt="Logo"/>-->
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink @click.native="openmodalCambiarlogo()" v-if="validaMarcaBlanca == 1">
          Cambiar Logo
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <!--<CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>-->
    <!--<CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>-->
    <CHeaderNav class="d-md-down-none mr-auto">
              <!--<CButton
                color="primary"
                v-c-popover="{
                  header: 'Popover!',
                  content: 'I start <strong>open</strong>',
                  placement: 'bottom-end'
                }"
              >
                Click me
              </CButton>-->
        <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink @click.native="obtenerMensajePse1">
           <span v-if="validaOpcionpse== 'Pago Pendiente'" class="alert alert-warning mt-2 alerta-header-ps" 
                v-c-popover="obtenerMensajePse"
           > Usted tiene una transaccion Pendiente PSE <strong>&nbsp; Ver mas &nbsp;</strong> </span>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-2">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
           <strong>Creditos Sms: &nbsp;</strong> <span> {{saldoActualUsuario}}</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
           <strong>Creditos Mailing: &nbsp;</strong> <span> {{saldoActualUsuarioMail}}</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="validaUsuarioPadre == 0">
        <CHeaderNavLink>
           <strong>Saldo Billetera: &nbsp;</strong> <span>$ {{saldoBilleteraActualUsuario}}</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!--<TheHeaderDropdownAccnt/>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink @click.native="logout">
          <CIcon name="cil-lock-locked" /> Cerrar Sesion
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!--<TheHeaderDropdownAccnt/>-->
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
    <div>
       <CModal
            :show.sync="cambiar_logo_modal"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            color="dark"
          >
           <CRow>
            <CCol col="6">
               <p><strong>Bienvenido</strong>
                aqui puedes cambiar el logo de tu empresa, intentanlo es muy sencillo.</p>
            </CCol>
            <CCol col="6">
                <center>
                <img :src="urlpreview" alt="" align="center" width="100px" height="100px" />
                </center>
            </CCol>
        </CRow>
         <CRow>
            <CCol col="6">
                <strong>Adjuntar Imagen</strong>  
            </CCol>
          </CRow>
           <CRow class="ml-1">
            <CCol col="12">
                <CInputFile
                  label="Escoja el archivo..."
                  horizontal
                  @change="cargarArchivoEnvio"
                  :key="fileInputKey"
                />
            </CCol>
          </CRow>
            <template #header>
              <h6 class="modal-title">Cambiar Logo</h6>
              <CButtonClose @click="cambiar_logo_modal = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="cambiar_logo_modal = false" color="danger">Cancelar</CButton>
              <CButton  color="success" @click="cambiarLogo()">Guardar</CButton>
            </template>
          </CModal>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
    </div>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data () {
    return {
      mostrarNotificacion: 0,
      mensaje_success:'Datos Guardado Correctamente.',
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      saldoActual:0.00,
      cambiar_logo_modal:false,
      archivo_envio: null,
      fileInputKey:0,
      urlpreview: '',
      mensajepse:'',
    }
  },
  computed: {
    /*refreshSaldoC(){
      this.refreshSaldo();
    },*/
    validaOpcionpse(){
      try {
        return this.$store.state.auth.psevalida.respuesta;
      } catch (error) {
        return 'NO';
      }
    },
    obtenerMensajePse(){
      try {
        let pago = this.$store.state.auth.psevalida;
        pago = pago['pago'];
        let text = `
        <p>En este momento su <strong># ${pago['Id_Pago']}</strong> presenta un proceso de pago cuya transaccion se encuentra <strong>PENDIENTE</strong> de recibir confirmacion por parte de su entidad financiera,
          por favor espere unos minutos y vuelva a consultar mas tarde para verificar si su pago fue confirmado de forma exitosa.
          Si desea mayor informacion sobre el estado actual de su operacion puede comunicarse a nuestras lineas de atencion al cliente al telefono  <strong>57 310 5 39 11 11</strong>
          o enviar sus inquietudes al correo soporte@itcloudcolombia.com y pregunte por el estado de la transaccion <strong>${pago['codigo_transaccion']}</strong></p>
        `;
        document.getElementsByClassName('popover-old-body')[0].innerHTML  = text;
        return {header: 'Transaccion Pendiente',content:text,placement: 'bottom-end'};
      } catch (error) {
        let text = `
        <p>En este momento presenta un proceso de pago cuya transaccion se encuentra <strong>PENDIENTE</strong> de recibir confirmacion por parte de su entidad financiera,
          por favor espere unos minutos y vuelva a consultar mas tarde para verificar si su pago fue confirmado de forma exitosa.
          Si desea mayor informacion sobre el estado actual de su operacion puede comunicarse a nuestras lineas de atencion al cliente al telefono  <strong>57 310 5 39 11 11</strong>
          o enviar sus inquietudes al correo soporte@itcloudcolombia.com y pregunte por el estado de la transaccion </p>
        `;
        return {header: 'Transaccion Pendiente',content:text,placement: 'bottom-end'};
      }
    },
    obtenerLogo(){
      return this.$store.state.auth.logo_web;
    },
    validaMarcaBlanca(){
      try {
        return this.$store.state.auth.user.sms.marca_blanca;
      } catch (error) {
        return 0
      }
      /*if (this.$store.state.auth.user.sms == null) {
          return 0
      }else{
          return this.$store.state.auth.user.sms.marca_blanca;
      }*/
      //return this.$store.state.auth.user.sms != null ? this.$store.state.auth.user.sms.marca_blanca : 0;
    },
    saldoActualUsuario(){
      try {
        return this.creditosConversorMoneda(this.$store.state.auth.user.sms.saldo_actual);
      } catch (error) {
        return 0
      }
      //console.log(this.$store.state.auth.user);
      /*if (this.$store.state.auth.user.sms == null) {
          return 0.00
      }else{
          return this.creditosConversorMoneda(this.$store.state.auth.user.sms.saldo_actual);
      }*/
      
    },
    saldoActualUsuarioMail(){
      try {
        return this.creditosConversorMoneda(this.$store.state.auth.user.sms.saldo_mail);
      } catch (error) {
        return 0
      }
    },
    saldoBilleteraActualUsuario(){
      try {
        return this.creditosConversorMoneda(this.$store.state.auth.user.sms.saldo_billetera);
      } catch (error) {
        return 0
      }    
    },
    validaUsuarioPadre(){
      try {
        return this.$store.state.auth.user.padre;
      } catch (error) {
        return 1
      }    
    }
  },
  methods:{
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
    refreshSaldo() {
       setTimeout(() => {
          //console.log(this.$store.state.auth.user);
          this.saldoActual =  this.$store.state.auth.user.sms.saldo_actual;
       },500);
     },
     openmodalCambiarlogo(){
       this.cambiar_logo_modal = true
       this.urlpreview=this.$store.state.auth.logo_web;
     },
     cargarArchivoEnvio(files,event) {
        this.archivo_envio = files[0]
        if (this.archivo_envio.name != undefined) {
            let extension = this.getFileExtension2(this.archivo_envio.name)
            if (extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                //console.log(this.archivo_envio);
                this.previewImagen(this.archivo_envio);
            }else{
                this.fileInputKey++;
                this.archivo_envio = null;
                this.mostrarNotificacionAlerta ++; 
                this.mensaje_warning='Solo se Permite Archivos PNG , JPG y JPEG'
            }
        }
      },
      getFileExtension2(filename) {
        return (filename.split('.').pop()).toLowerCase();
      },
      previewImagen(file){
        this.urlpreview = URL.createObjectURL(file);
      },
      cambiarLogo(){
        if (this.archivo_envio == null) {
            this.mostrarNotificacionAlerta ++; 
            this.mensaje_warning='Para Guardar Debe Seleccionar Una Imagen'
        }else{
          let InstFormData = new FormData();
          InstFormData.append('archivo_envio',this.archivo_envio); 
          this.$http.post('/sms/cambiar/logo',InstFormData,{
            headers : {'content-type': 'multipart/form-data'}
          }).then(response => {
                if (response.data.valida == 'OK'){
                  this.fileInputKey++;
                  this.archivo_envio = null;
                  this.mensaje_success = 'La Imagen Se Cambio Correctamente';
                  this.mostrarNotificacion++;
                  let url = response.data.logo
                  this.$store.dispatch('auth/nuevoLogo',url);
                }
          }).catch(()=>{
              this.mostrarNotificacionError++;
          }); 
        }
      },
      logout() {
        this.$store.dispatch('auth/logout');
      },
      obtenerMensajePse1(){
        this.$store.dispatch('auth/getValidaPse');
      }
      /*obtenerMensajePse1(){
        setTimeout(()=>{
          try {
              let pago = this.$store.state.auth.psevalida;
              console.log(pago);
              pago = pago['pago'];
              let text = `
              <p>En este momento su <strong># ${pago['Id_Pago']}</strong> presenta un proceso de pago cuya transaccion se encuentra <strong>PENDIENTE</strong> de recibir confirmacion por parte de su entidad financiera,
                por favor espere unos minutos y vuelva a consultar mas tarde para verificar si su pago fue confirmado de forma exitosa.
                Si desea mayor informacion sobre el estado actual de su operacion puede comunicarse a nuestras lineas de atencion al cliente al telefono  <strong>(571) 486 33 18</strong>
                o enviar sus inquietudes al correo soporte@itcloudcolombia.com y pregunte por el estado de la transaccion <strong>${pago['codigo_transaccion']}</strong></p>
              `;
              document.getElementsByClassName('popover-old-body')[0].innerHTML  = text;
              console.log(text);
              //return {header: 'Transaccion Pendiente',content:text,placement: 'bottom-end'};
            } catch (error) {
              console.log(error);
              let text = `
              <p>En este momento presenta un proceso de pago cuya transaccion se encuentra <strong>PENDIENTE</strong> de recibir confirmacion por parte de su entidad financiera,
                por favor espere unos minutos y vuelva a consultar mas tarde para verificar si su pago fue confirmado de forma exitosa.
                Si desea mayor informacion sobre el estado actual de su operacion puede comunicarse a nuestras lineas de atencion al cliente al telefono  <strong>(571) 486 33 18</strong>
                o enviar sus inquietudes al correo soporte@itcloudcolombia.com y pregunte por el estado de la transaccion </p>
              `;
              document.getElementsByClassName('popover-old-body')[0].innerHTML  = text;
              //return {header: 'Transaccion Pendiente',content:text,placement: 'bottom-end'};
            }
        },200)
    },*/
  },
  mounted () {
    let $self = this;
    setTimeout(() => {
      $self.$store.dispatch('auth/getValidaPse');
    },200)
    //this.refreshSaldo();
    /*document.body.addEventListener('click', function (evt) {
        if (evt.target.className === 'alerta-header-ps') {
            console.log("hola");
        }
    }, false);*/
  }
}
</script>
